import React, { useContext, useEffect } from "react"

import { useForm } from "react-hook-form"

import { DatabaseContext } from "../../context/database/databaseContext"
import { AlertContext } from "../../context/alert/alertContext"

import { LoaderInput } from "../../components/LoaderInput"

import './index.scss'

export const Login = () => {
    const alert = useContext(AlertContext)

    const { signin, loading, notice } = useContext( DatabaseContext )

    const { register, formState: { errors }, handleSubmit } = useForm()

    const onSubmit = (data) => {
        signin(data)
    };

    useEffect(() => {
        notice?.message ? alert.show(notice.message, notice.type) : alert.hide()
    }, [notice]);

    return (
        <>
            <div className="wrapper">
                <div className="form-auth-wrapper">
                    <div className="block-content">
                        <form method="POST" onSubmit={handleSubmit(onSubmit)} >
                            <div className="mb-3">
                                <label htmlFor="loginField" className="form-label">Логин</label>
                                <input
                                    {
                                        ...register("login", {
                                            required: "Field is required"
                                        })
                                    }
                                    placeholder="Your Login"
                                    className="form-control"
                                    id="loginField"
                                />
                            </div>
                            {errors.login && <p style={{color: 'red'}}>{errors.login?.message || "Error"}</p>}
                            <div className="w-input">
                                <label htmlFor="passwordField" className="form-label">Пароль</label>
                                <input
                                    {
                                        ...register("password", {
                                            required: "Field is required"
                                        })
                                    }
                                    type="password"
                                    placeholder="Your Password"
                                    className="form-control"
                                    id="passwordField"
                                />
                            </div>
                            {errors.password && <p style={{color: 'red'}}>{errors.password?.message || "Error"}</p>}
                            <div className="mt-5 flex w-190">
                                {
                                    loading 
                                    ? <LoaderInput />
                                    : <input type="submit" className="btn-submit" value="Вход" />
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}