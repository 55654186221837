import React, { Component, useContext } from "react"

import { Route, Redirect } from "react-router-dom"

import { DatabaseContext } from "../context/database/databaseContext"

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { accessToken } = useContext(DatabaseContext)

    return (
        <Route {...rest} render={props => accessToken ? (
            <Component {...props} />
        ): (
            <Redirect to={'/login'} /> 
        )} />
    )
}
