import React, { useContext } from "react"

import { DatabaseContext } from "../context/database/databaseContext"

import { NavLink } from "react-router-dom"

import Logo from "../assets/icon/logo.svg"
import Exit from "../assets/icon/exit.svg"

export const Navbar = () => {
    const { logout, accessToken } = useContext(DatabaseContext)

    return (
        <div className="bg-blue">
            <nav className="custom-container navbar navbar-dark navbar-expand-lg d-flex justify-content-center">
                <div className="navbar-brand">
                    <NavLink
                        className="nav-link"
                        to="/"
                        exact
                    >
                        <img src={Logo} />
                    </NavLink>
                    
                </div>
                {accessToken && <button onClick={() => logout()} className="btn btn-logout">&nbsp;<img width={30} height={30} src={Exit} alt="exit" /></button>}
            </nav>
        </div>
    )
}