export const SHOW_LOADER = 'SHOW_LOADER'

export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_ALERT = 'SHOW_ALERT'

export const SIGNUP = 'SIGNUP'
export const SIGNIN = 'SIGNIN'
export const LOGOUT = 'LOGOUT'

export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'

export const FETCH_ORDERS = 'FETCH_ORDERS'

export const FETCH_COMMENTS = 'FETCH_COMMENTS'

export const SET_MESSAGE = 'SET_MESSAGE'
export const UNSET_MESSAGE = 'UNSET_MESSAGE'

export const SHOW_CONFIRM = 'SHOW_CONFIRM'
export const HIDE_CONFIRM = 'HIDE_CONFIRM'

export const SEND_EMAILS = 'SEND_EMAILS'

export const FETCH_SUBSCRIBERS = 'FETCH_SUBSCRIBERS'
export const EXPORT_SUBSCRIBERS = 'EXPORT_SUBSCRIBERS'
