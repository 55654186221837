import React from "react";

import { NavLink } from "react-router-dom"

import ArticleImg from "../../../assets/image/article.png"
import CommentImg from "../../../assets/image/comment.png"
import OrderImg from "../../../assets/image/order.png"
import EmailImg from "../../../assets/image/emails.png"

import "./index.scss"

export const Main = () => {
    return (
        <div className="container navbar navbar-dark justify-content-center">
            <ul className="navbar-nav">
                <li className="nav-item d-flex align-items-center">
                    <NavLink
                        className="nav-link"
                        to="/articles/"
                        exact
                    >
                        <img src={ArticleImg} height={200} alt="acticles" />
                        <span>Статьи</span>
                    </NavLink>
                </li>
                <li className="nav-item d-flex align-items-center">
                    <NavLink
                        className="nav-link"
                        to="/comments/"
                    >
                        <img src={CommentImg} height={200} alt="comments" />
                        <span>Отзывы</span>
                    </NavLink>
                </li>
                <li className="nav-item d-flex align-items-center">
                    <NavLink
                        className="nav-link"
                        to="/orders/"
                    >
                        <img src={OrderImg} height={200} alt="orders" />
                        <span>Заявки</span>
                    </NavLink>
                </li>
                <li className="nav-item d-flex align-items-center">
                    <NavLink
                        className="nav-link"
                        to="/subscribers/"
                    >
                        <img src={EmailImg} height={200} alt="emails" />
                        <span>Рассылки</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
} 