import React, { useState, useContext, useEffect } from "react"

import CameraIcon from "../../../assets/icon/camera.svg"
import ImageIcon from "../../../assets/icon/image.svg"
import SearchIcon from "../../../assets/icon/icon-search.png"

import { DatabaseContext } from "../../../context/database/databaseContext"
import { AlertContext } from "../../../context/alert/alertContext"
import { ConfirmContext } from "../../../context/confirm/confirmContext"

import { LoaderInput } from "../../../components/LoaderInput"
import { ListSubscribers } from "../../../components/ListSubscribers"

import "./index.scss"

const url = process.env.REACT_APP_DB_URL
const pass = process.env.REACT_APP_PASS

export const Subscribers = () => {
    const alert = useContext(AlertContext)
    const { confirm } = useContext(ConfirmContext)

    const { loading, fetchSubscribers, deleteSubscribers, sendEmails, subscribers, notice, exoportSubscribers } = useContext(DatabaseContext)
    
    const [ currentPage, setCurrentPage ] = useState(1)

    useEffect(() => {
        fetchSubscribers(currentPage)
    }, [currentPage])

    useEffect(() => {
        notice?.message && alert.show(notice.message, notice.type)
    }, [notice])

    const [ formState, setFormState ] = useState({
        title: '',
        description: '',
        link: '',
        text: ''
    })

    const onSubmit = async (e) => {
        e.preventDefault()

        showConfirm('Вы уверены, что хотите отправить сообщение?')
    }

    const showConfirm = async (text) => {
        const isConfirmed = await confirm(text);

        if (isConfirmed) {
            console.log('sending...')
            sendEmails(formState)
        } else {
            console.log('cancel...')
        }
    }

    const [ filters, setFilters ] = useState({
        email: ''
    })

    const onFilter = () => {
        fetchSubscribers(currentPage, filters.email)
    }

    return (
        <>
            <div className="custom-container">
                <h1>рассылки</h1>
                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-wrapper mt-3" style={{height: 'auto'}}>
                        <div>
                            <input
                                value={formState.title}
                                onChange={e => setFormState({...formState, title: e.target.value})}
                                placeholder="Заглавный текст *required"
                            />
                            <textarea
                                value={formState.description}
                                onChange={e => setFormState({...formState, description: e.target.value})}
                                placeholder="Описание *required"
                            />
                            <input
                                value={formState.link}
                                onChange={e => setFormState({...formState, link: e.target.value})}
                                placeholder="Ссылка *optional"
                            />
                            <input
                                value={formState.text}
                                onChange={e => setFormState({...formState, text: e.target.value})}
                                placeholder="Текст кнопки *optional"
                            />
                        </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-start button-wrapper">
                        {
                            loading 
                            ? <LoaderInput /> 
                            : <button className="submit" type="submit">Разослать</button>
                        }
                    </div>
                </form>
                <hr />
                <div className="d-flex justify-content-start button-wrapper">
                    {
                        loading 
                        ? <LoaderInput /> 
                        : <button className="submit" onClick={() => {
                            window.open(`${url}/admin/subscribers/export/${pass}`, '_blank');
                        }}>Получить csv</button>
                    }
                </div>
                <hr />
                <div className="d-flex justify-content-between header">
                    <h4>Подписчики</h4>
                    <div className="input-search-wrapper">
                        <input
                            value={filters.email}
                            onChange={e => setFilters({...filters, email: e.target.value})}
                            placeholder="Поиск по email"
                        />
                        <button onClick={() => onFilter()}><img src={SearchIcon} /></button>
                    </div>
                </div>
                <div className="articlesList mt-3">
                    {
                        loading 
                        ? <LoaderInput /> 
                        : <ListSubscribers
                            subscribers={subscribers}
                            deleteSubscribers={deleteSubscribers}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            confirm={confirm} />
                    }
                </div>
            </div>
        </>
    )
}