import React from "react";

import { BrowserRouter, Switch } from "react-router-dom"

import { PublicRoute } from "./components/PublicRoute"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { Login } from "./pages/Login"
import { Main } from "./pages/Admin/Main"
import { Articles } from "./pages/Admin/Aricles"
import { Orders } from "./pages/Admin/Orders";
import { Comments } from "./pages/Admin/Comments";
import { Subscribers } from "./pages/Admin/Subscribers";

import { Navbar } from "./components/Navbar"
import { DatabaseState } from "./context/database/DatabaseState"
import { AlertState } from "./context/alert/AlertState"
import { ConfirmState } from "./context/confirm/ConfirmState";
import { Alert } from "./components/Alert"
import { ConfirmDialog } from "./components/ConfirmDialog"

function App() {
  return (
    <DatabaseState>
      <AlertState>
        <ConfirmState>
          <BrowserRouter>
            <Navbar />
            <div className="container">
              <Alert/>
              <ConfirmDialog />
              <Switch>
                <ProtectedRoute path="/" exact component={Main} />
                <ProtectedRoute path="/articles/" component={Articles} />
                <ProtectedRoute path="/orders/" component={Orders} />
                <ProtectedRoute path="/comments/" component={Comments} />
                <ProtectedRoute path="/subscribers/" component={Subscribers} />
                <PublicRoute path="/login" component={Login} />
              </Switch>
            </div>
          </BrowserRouter>
        </ConfirmState>
      </AlertState>
    </DatabaseState>
  );
}

export default App;
