import React, { useState, useContext, useEffect } from "react"

import { LoaderInput } from "../../../components/LoaderInput"

import { DatabaseContext } from "../../../context/database/databaseContext"
import { AlertContext } from "../../../context/alert/alertContext"
import { ConfirmContext } from "../../../context/confirm/confirmContext"

import { ListComments } from "../../../components/ListComments"

import "./index.scss"

export const Comments = () => {
    const alert = useContext(AlertContext)
    const { confirm } = useContext(ConfirmContext)

    const { loading, fetchComments, deleteComment, comments, notice, approvedComment } = useContext(DatabaseContext)

    const [ currentPage, setCurrentPage ] = useState( 1 )

    useEffect(() => {
        fetchComments(currentPage, limit, status)
    }, [currentPage])

    useEffect(() => {
        notice?.message && alert.show(notice.message, notice.type)
    }, [notice])

    const [ limit, setLimit ] = useState(10)
    const [ status, setStatus ] = useState('new')

    const onFilter = (newStatus) => {
        setStatus(newStatus)

        fetchComments(currentPage, limit, newStatus)
    }

    return (
        <>
            <h1>Отзывы</h1>
            <div>
                <select onChange={e => onFilter(e.target.value)} defaultValue={status}>
                    <option value="new">Ожидают подтверждения</option>
                    <option value="confirmed">Все отзывы</option>
                </select>
            </div>
            <div className="articlesList mt-3">
                {
                    loading 
                    ? <LoaderInput /> 
                    : <ListComments
                        comments={comments}
                        deleteComment={deleteComment}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        approvedComment={approvedComment}
                        confirm={confirm} />
                }
            </div>
        </>
    )
}