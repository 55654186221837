import React, { useReducer } from 'react'
import { ConfirmContext } from './confirmContext'
import { confirmReducer } from './confirmReducer'
import { SHOW_CONFIRM, HIDE_CONFIRM } from '../types'

let resolveCallback

export const ConfirmState = ({children}) => {
    const [confirmState, dispatch] = useReducer(confirmReducer, { show: false, text: '' })

    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };

    const confirm = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: { text }
        })
        return new Promise((res, rej) => {
            resolveCallback = res
        });
    }

    const closeConfirm = () => {
        dispatch({type: HIDE_CONFIRM})
    }

    return (
        <ConfirmContext.Provider value={{
            onConfirm, onCancel, confirm,
            confirmState
        }}>
            {children}
        </ConfirmContext.Provider>
    )
}