import { 
    SHOW_LOADER, 
    SIGNIN,
    LOGOUT,
    SET_MESSAGE,
    FETCH_ARTICLES,
    CREATE_ARTICLE,
    UPDATE_ARTICLE,
    FETCH_ORDERS,
    FETCH_COMMENTS,
    SEND_EMAILS,
    FETCH_SUBSCRIBERS,
    EXPORT_SUBSCRIBERS
} from "../types"

const handlers = {
    [SHOW_LOADER]: state => ({...state, loading: true}),
    [SIGNIN]: (state, {payload}) => ({
        ...state,
        accessToken: payload,
        loading: false,
    }),
    [LOGOUT]: (state) => ({
        ...state,
        notice: null,
        accessToken: null
    }),
    [SET_MESSAGE]: (state, {payload}) => ({
        ...state,
        notice: {'message': payload.message, 'type': payload.type},
        loading: false
    }),
    [FETCH_ARTICLES]: (state, {payload}) => ({
        ...state,
        articles: {'data': payload.data, 'last_page': payload.last_page},
        notice: {},
        loading: false
    }),
    [CREATE_ARTICLE]: (state, {payload}) => ({
        ...state,
        notice: {'message': payload.message, 'type': payload.type},
        articles: payload.articles,
        loading: false
    }),
    [UPDATE_ARTICLE]: (state, {payload}) => ({
        ...state,
        notice: {'message': payload.message, 'type': payload.type},
        articles: payload.articles,
        loading: false
    }),
    [FETCH_ORDERS]: (state, {payload}) => ({
        ...state,
        orders: {'data': payload.data, 'last_page': payload.last_page},
        notice: {},
        loading: false
    }),
    [FETCH_COMMENTS]: (state, {payload}) => ({
        ...state,
        comments: {'data': payload.data, 'last_page': payload.last_page},
        notice: {},
        loading: false
    }),
    [FETCH_SUBSCRIBERS]: (state, {payload}) => ({
        ...state,
        subscribers: {'data': payload.data, 'last_page': payload.last_page},
        notice: {},
        loading: false
    }),
    [SEND_EMAILS]: state => ({...state, loading: false}),
    [EXPORT_SUBSCRIBERS]: state => ({...state, loading: false}),
    DEFAULT: state => state
}

export const databaseReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}