import React, { useState } from "react";

import ArrowDown from "../assets/icon/arrow-down.svg"

export const ListComments = ({ comments, deleteComment, setCurrentPage, currentPage, confirm, approvedComment }) => {  
    const [ isActive, setActive ] = useState(0)

    const showConfirm = async (id, approved = false) => {
        if(approved) {
            const isConfirmed = await confirm('Вы уверены, что хотите разместить отзыв?');

            if (isConfirmed) {
                console.log('approving...')
                setCurrentPage(1)
                approvedComment(id)
            } else {
                console.log('cancel...')
            }
        } else {
            const isConfirmed = await confirm('Вы уверены, что хотите удалить отзыв?');

            if (isConfirmed) {
                console.log('deleting...')
                setCurrentPage(1)
                deleteComment(id)
            } else {
                console.log('cancel...')
            }
        }
    }

    if (!comments.data) {
        return null
    }

    return (
        <div className="listOrders">
            {comments.data.length
            ? comments.data.map((comment, index) => (
                <div className="list-item" key={index}>
                    <div className="line">
                        <span>{comment.email.length > 25 ? comment.email.slice(0, 25) + '...' : comment.email}</span>
                        {
                            isActive == comment.id
                            ? <button className="button-plus" onClick={() => setActive(0)}>-</button>
                            : <button className="button-min" onClick={() => setActive(comment.id)}>+</button>
                        }
                    </div>
                    {isActive == comment.id && <div className="line">
                        <span>
                            {comment.message}
                            <br /><br />
                            <b>{comment.author}</b>
                        </span>
                        <span>Article ID: {comment.article_id}</span>
                        {
                            comment.status == 'new'
                            ? <button onClick={() => showConfirm(comment.id, true)}>Разместить</button>
                            : <button onClick={() => showConfirm(comment.id)}>Удалить</button>
                        }
                    </div>}
                </div>
            ))
            : <div className="list-item">Not Found</div>}
            {currentPage != comments.last_page && <button className="pageArrow" onClick={() => setCurrentPage(currentPage+1)}><img src={ArrowDown} /></button>}
        </div>
    )
}