import React, { useState, useContext, useEffect } from "react"

import { LoaderInput } from "../../../components/LoaderInput"

import { DatabaseContext } from "../../../context/database/databaseContext"
import { AlertContext } from "../../../context/alert/alertContext"
import { ConfirmContext } from "../../../context/confirm/confirmContext"

import { ListAccordItems } from "../../../components/ListAccordItems"

import "./index.scss"

export const Orders = () => {
    const alert = useContext(AlertContext)
    const { confirm } = useContext(ConfirmContext)

    const { loading, fetchOrders, deleteOrder, orders, notice } = useContext(DatabaseContext)

    const [ currentPage, setCurrentPage ] = useState( 1 )

    useEffect(() => {
        fetchOrders(currentPage, limit)
    }, [currentPage])

    useEffect(() => {
        notice?.message && alert.show(notice.message, notice.type)
    }, [notice])

    const [ limit, setLimit ] = useState(10)

    const onFilter = () => {
        fetchOrders(currentPage, limit)
    }

    return (
        <>
            <h2>Заявки</h2>
            <div className="articlesList mt-3">
                {
                    loading 
                    ? <LoaderInput /> 
                    : <ListAccordItems
                        orders={orders}
                        deleteOrder={deleteOrder}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        confirm={confirm} />
                }
            </div>
        </>
    )
}