import { SHOW_CONFIRM, HIDE_CONFIRM } from "../types"

const handlers = {
    [SHOW_CONFIRM]: (state, {payload}) => ({...state, show: true, text: payload.text }),
    [HIDE_CONFIRM]: state => ({...state, show: false}),
    DEFAULT: state => state
}

export const confirmReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}