import React, { useContext } from "react"

import { Route, Redirect } from "react-router-dom"

import { DatabaseContext } from "../context/database/databaseContext"

export const PublicRoute = ({ component: Component, ...rest }) => {
    const { accessToken } = useContext(DatabaseContext)

    return (
        <Route {...rest} render={props => accessToken ? (
            <Redirect to={'/'} /> 
        ): (
            <Component {...props} />
        )} />
    )
}
