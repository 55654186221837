import React from "react";

import ArrowDown from "../assets/icon/arrow-down.svg"

export const ListSubscribers = ({ subscribers, deleteSubscribers, setCurrentPage, currentPage, confirm }) => {  
    const showConfirm = async (id) => {
        const isConfirmed = await confirm('Вы уверены, что хотите удалить подписчика?');

        if (isConfirmed) {
            console.log('deleting...')
            setCurrentPage(1)
            deleteSubscribers(id)
        } else {
            console.log('cancel...')
        }
    }

    if (!subscribers.data) {
        return null
    }

    return (
        <div className="listArticles">
            {subscribers.data.length
            ? subscribers.data.map((subscriber, index) => (
                <div className="list-item" key={index}>
                    <span>{subscriber.email.length > 25 ? subscriber.email.slice(0, 25) + '...' : subscriber.email}</span>
                    <span>{subscriber.created_at.split('T')[0]}</span>
                    <button onClick={() => showConfirm(subscriber.id)}>Отписать</button>
                </div>
            ))
            : <div className="list-item">Not Found</div>}
            {currentPage != subscribers.last_page && <button className="pageArrow" onClick={() => setCurrentPage(currentPage+1)}><img src={ArrowDown} /></button>}
        </div>
    )
}