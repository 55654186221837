import React, { useState } from "react";

import ArrowDown from "../assets/icon/arrow-down.svg"

export const ListAccordItems = ({ orders, deleteOrder, setCurrentPage, currentPage, confirm }) => {  
    const [ isActive, setActive ] = useState(0)

    const showConfirm = async (id) => {
        const isConfirmed = await confirm('Вы уверены, что хотите удалить заявку?');

        if (isConfirmed) {
            console.log('deleting...')
            setCurrentPage(1)
            deleteOrder(id)
        } else {
            console.log('cancel...')
        }
    }

    if (!orders.data) {
        return null
    }

    return (
        <div className="listOrders">
            {orders.data.length
            ? orders.data.map((order, index) => (
                <div className="list-item" key={index}>
                    <div className="line">
                        <span>{order.name.length > 25 ? order.name.slice(0, 25) + '...' : order.name}</span>
                        <span>{order.category}</span>
                        <span>{order.created_at.split('T')[0]}</span>
                        {
                            isActive == order.id
                            ? <button className="button-plus" onClick={() => setActive(0)}>-</button>
                            : <button className="button-min" onClick={() => setActive(order.id)}>+</button>
                        }
                    </div>
                    {isActive == order.id && <div className="line">
                        <span>
                            {order.message}
                            <br /><br />
                            {order.email}
                        </span>
                        <span>{order.button}</span>
                        <button onClick={() => showConfirm(order.id)}>Удалить</button>
                    </div>}
                </div>
            ))
            : <div className="list-item">Not Found</div>}
            {currentPage != orders.last_page && <button className="pageArrow" onClick={() => setCurrentPage(currentPage+1)}><img src={ArrowDown} /></button>}
        </div>
    )
}