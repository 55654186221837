import React from "react";

import ArrowDown from "../assets/icon/arrow-down.svg"

export const ListItems = ({ articles, deleteArticle, setCurrentPage, currentPage, updateArticleState, confirm }) => {  
    const showConfirm = async (id) => {
        const isConfirmed = await confirm('Вы уверены, что хотите удалить статью?');

        if (isConfirmed) {
            console.log('deleting...')
            setCurrentPage(1)
            deleteArticle(id)
        } else {
            console.log('cancel...')
        }
    }

    if (!articles.data) {
        return null
    }

    return (
        <div className="listArticles">
            {articles.data.length
            ? articles.data.map((article, index) => (
                <div className="list-item" key={index}>
                    <span>{article.title.length > 25 ? article.title.slice(0, 25) + '...' : article.title}</span>
                    <span>{article.created_at.split('T')[0]}</span>
                    <span>{article.author.length > 10 ? article.author.slice(0, 10) + '...' : article.author}</span>
                    <button onClick={() => updateArticleState(article)}>Изменить</button>
                    <button onClick={() => showConfirm(article.id)}>Удалить</button>
                </div>
            ))
            : <div className="list-item">Not Found</div>}
            {currentPage != articles.last_page && <button className="pageArrow" onClick={() => setCurrentPage(currentPage+1)}><img src={ArrowDown} /></button>}
        </div>
    )
}