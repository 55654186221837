import React, { useContext } from "react";

import { ConfirmContext } from '../context/confirm/confirmContext'

export const ConfirmDialog = () => {
    const { confirmState, onCancel, onConfirm } = useContext(ConfirmContext)

    if(!confirmState.show) {
        return null
    }

    return (
        <>
            <div className="bg-modal">
                <div className="modal-agree" id="promiseConfirm">
                    <h3>{confirmState.text}</h3>
                    <p> Данное действие нельзя будет отменить. Нажимая кнопку ДА вы берете на себя ответсвенность за последствия и соглашаетесь на пытку электрическим током в случае если действие влечет за собой отрицательные последствия.</p>
                    <div className="button-group">
                        <button onClick={onConfirm}>Да</button>
                        <button onClick={onCancel}>Нет</button>
                    </div>
                </div>
            </div>
        </>
        
    )
}