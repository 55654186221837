import React, { useState, useContext, useEffect } from "react"

import CameraIcon from "../../../assets/icon/camera.svg"
import ImageIcon from "../../../assets/icon/image.svg"
import SearchIcon from "../../../assets/icon/icon-search.png"

import { DatabaseContext } from "../../../context/database/databaseContext"
import { AlertContext } from "../../../context/alert/alertContext"
import { ConfirmContext } from "../../../context/confirm/confirmContext"

import { LoaderInput } from "../../../components/LoaderInput"
import { ListItems } from "../../../components/ListItems"

import "./index.scss"

export const Articles = () => {
    const alert = useContext(AlertContext)
    const { confirm } = useContext(ConfirmContext)

    const { loading, fetchArticles, createArticle, deleteArticle, updateArticle, articles, notice, uploadFiles } = useContext(DatabaseContext)
    
    const [ currentPage, setCurrentPage ] = useState( 1 )

    useEffect(() => {
        fetchArticles(currentPage, filters.title)
    }, [currentPage])

    useEffect(() => {
        notice?.message && alert.show(notice.message, notice.type)
    }, [notice])

    const [ formState, setFormState ] = useState({
        title: '',
        description: '',
        image: '',
        category: '',
        author: '',
        author_image: '',
        content: '',
        huu: ''
    })

    console.log(formState)

    const [ filters, setFilters ] = useState({
        title: ''
    })

    const [ contentState, setContent ] = useState([])

    const [ uploadForm, setUploadForm ] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault()

        let result = {...formState, content: JSON.stringify(contentState)}

        console.log(result)

        if (!!result.id) {
            showConfirm('Вы уверены, что хотите изменить статью?')
        } else {
            showConfirm('Вы уверены, что хотите добавить статью?')
        }
    }

    const addInputField = (type) => {
        let currentContentState = [...contentState]

        switch (type) {
            case 'h':
                currentContentState.push({ "content": "", "option": "h" })
                setContent(currentContentState)
                break;
            case 'p':
                currentContentState.push({ "content": "", "option": "p" })
                setContent(currentContentState)
                break;
            case 'img':
                uploadImage()
                currentContentState.push({ "content": "", "option": "img" })
                setContent(currentContentState)
                break;
            case 'video':
                currentContentState.push({ "content": "", "option": "video" })
                setContent(currentContentState)
                break;
            default:
                break;
        }
    }

    const changeContentState = (value, index) => {
        let currentStateContent = [...contentState]
        currentStateContent[index].content = value
        setContent(currentStateContent)
    }

    const removeInputItem = (index) => {
        let currentStateContent = [...contentState]

        currentStateContent.splice(index, 1);

        setContent(currentStateContent)
    }

    const updateArticleState = (data) => {
        setFormState(data)
        setContent(JSON.parse(data.content))
    }

    const uploadImage = () => {
        setUploadForm(true)
    }

    const cleanState = () => {
        setFormState({
            title: '',
            description: '',
            image: '',
            category: '',
            author: '',
            author_image: '',
            huu: '',
            content: ''
        })
        setContent([])
    }

    const onFilter = () => {
        fetchArticles(currentPage, filters.title)
    }

    const showConfirm = async (text) => {
        const isConfirmed = await confirm(text);

        let result = {...formState, content: JSON.stringify(contentState)}

        if (isConfirmed) {
            if (!!result.id) {
                console.log('updating...')
                updateArticle(result, result.id)
            } else {
                console.log('creating...')
                createArticle(result)
            }
        } else {
            console.log('cancel...')
        }
    }

    return (
        <>
            {uploadForm && 
                <div className="bg-modal">
                    <div className="modal-agree px-3 d-flex justify-content-between">
                        <input type="file" onChange={(e)=> { 
                            uploadFiles(e.target.files[0])
                            setUploadForm(false)
                        }} />
                        <button className="button-close" onClick={() => setUploadForm(false)}>&#10006;</button>
                    </div>
                </div>
            }
            <div className="custom-container">
                <h1>Статьи</h1>
                <div className="d-flex justify-content-between header">
                    <h4>добавить / изменить</h4>
                    <div className="d-flex justify-content-between button-group">
                        <button className="button-customize" onClick={() => addInputField('h')}>T</button>
                        <button className="button-customize" onClick={() => addInputField('p')}>Tt</button>
                        <button className="button-customize" onClick={() => addInputField('img')}>
                            <img src={ImageIcon} width={30} height={30} />
                        </button>
                        <button className="button-customize" onClick={() => addInputField('video')}>
                            <div className="d-flex"><img src={CameraIcon} height={24} width={24} /></div>
                            <span>https</span>
                        </button>
                        <button className="button-customize" onClick={() => cleanState()}>&#10008;</button>
                    </div>
                </div>
                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-wrapper mt-3">
                        <div>
                            <input
                                value={formState.title}
                                onChange={e => setFormState({...formState, title: e.target.value})}
                                placeholder="Название статьи *required"
                            />
                            <textarea
                                value={formState.description}
                                onChange={e => setFormState({...formState, description: e.target.value})}
                                placeholder="Описание статьи *required"
                            />
                            <input
                                value={formState.image}
                                onChange={e => setFormState({...formState, image: e.target.value})}
                                placeholder="Картинка статьи *required"
                            />
                            <select
                                value={formState.category}
                                onChange={e => setFormState({...formState, category: e.target.value})}
                            >
                                <option value="">Выбрать направление</option>
                                <option value="Search Engine Optimization">Search Engine Optimization</option>
                                <option value="Social Media Marketing">Social Media Marketing</option>
                                <option value="Google Advertising">Google Advertising</option>
                                <option value="Web Development">Web Development</option>
                                <option value="Design Solutions">Design Solutions</option>
                                <option value="Digital Audio Marketing">Digital Audio Marketing</option>
                            </select>
                            <select
                                value={formState.author}
                                onChange={e => setFormState({...formState, author: e.target.value})}
                            >
                                <option value="">Присвоить автора</option>
                                <option value="Dmitriy К. Google Ad Expert">Dmitriy К. Google Ad Expert</option>
                                <option value="George S. SEO Expert">George S. SEO Expert</option>
                                <option value="Kseniya I. Content Marketing Expert">Kseniya I. Content Marketing Expert</option>
                                <option value="Maxim D. Social Media Expert">Maxim D. Social Media Expert</option>
                                <option value="Rustam K. Lead Designer">Rustam K. Lead Designer</option>
                                <option value="Vladislav L. Digital Audio Marketer">Vladislav L. Digital Audio Marketer</option>
                                <option value="Dmitriy N. Full-Stack Developer">Dmitriy N. Full-Stack Developer</option>
                            </select>
                            <input
                                value={formState.author_image}
                                onChange={e => setFormState({...formState, author_image: e.target.value})}
                                placeholder="Фото автора *required"
                            />
                            <input
                                value={formState.huu}
                                onChange={e => setFormState({...formState, huu: e.target.value})}
                                placeholder="ЧПУ *required"
                            />
                            <hr />
                            <div id="content">
                                {
                                    contentState.map((item, index) => {
                                        if(item.option == 'h') {
                                            return <div className="inputListItem" key={index}>
                                                <input
                                                    placeholder="Введите заголовок"
                                                    value={contentState[index].content}
                                                    onChange={e => changeContentState(e.target.value, index)}
                                                />
                                                <div onClick={() => removeInputItem(index)}><span>&times;</span></div>
                                            </div>
                                        }

                                        if(item.option == 'p') {
                                            return <div className="inputListItem" key={index}>
                                                <input
                                                    placeholder="Введите текст"
                                                    value={contentState[index].content}
                                                    onChange={e => changeContentState(e.target.value, index)}
                                                />
                                                <div onClick={() => removeInputItem(index)}><span>&times;</span></div>
                                            </div>
                                        }

                                        if(item.option == 'img') {
                                            return <div className="inputListItem" key={index}>
                                                <input
                                                    placeholder="Введите ссылку на картинку"
                                                    value={contentState[index].content}
                                                    onChange={e => changeContentState(e.target.value, index)}
                                                />
                                                <div onClick={() => removeInputItem(index)}><span>&times;</span></div>
                                            </div>
                                        }

                                        if(item.option == 'video') {
                                            return <div className="inputListItem" key={index}>
                                                <input
                                                    placeholder="Введите ссылку на видео"
                                                    value={contentState[index].content}
                                                    onChange={e => changeContentState(e.target.value, index)}
                                                />
                                                <div onClick={() => removeInputItem(index)}><span>&times;</span></div>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-start button-wrapper">
                        {
                            loading 
                            ? <LoaderInput /> 
                            : <button className="submit" type="submit">РАЗМЕСТИТЬ</button>
                        }
                    </div>
                </form>
                <hr />
                <div className="d-flex justify-content-between header">
                    <h4>удалить / изменить</h4>
                    <div className="input-search-wrapper">
                        <input
                            value={filters.title}
                            onChange={e => setFilters({...filters, title: e.target.value})}
                            placeholder="Поиск по заголовку статьи"
                        />
                        <button onClick={() => onFilter()}><img src={SearchIcon} /></button>
                    </div>
                </div>
                <div className="articlesList mt-3">
                    {
                        loading 
                        ? <LoaderInput /> 
                        : <ListItems
                            articles={articles}
                            updateArticleState={updateArticleState}
                            deleteArticle={deleteArticle}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            confirm={confirm} />
                    }
                </div>
            </div>
        </>
    )
}