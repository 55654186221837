import React, { useReducer } from "react";
import { 
    SHOW_LOADER,
    SIGNIN,
    LOGOUT,
    SET_MESSAGE,
    FETCH_ARTICLES,
    CREATE_ARTICLE,
    UPDATE_ARTICLE,
    FETCH_ORDERS,
    FETCH_COMMENTS,
    SEND_EMAILS,
    FETCH_SUBSCRIBERS,
    EXPORT_SUBSCRIBERS
} from "../types";
import { DatabaseContext } from "./databaseContext"
import { databaseReducer } from "./databaseReducer";

import axios from 'axios'

const url = process.env.REACT_APP_DB_URL

export const DatabaseState = ({children}) => {
    const initialState = {
        accessToken: null,
        notice: {},
        loading: false,
        articles: {},
        orders: {},
        comments: {},
        subscribers: {}
    }

    const [state, dispatch] = useReducer(databaseReducer, initialState)

    state.accessToken = localStorage.getItem('accessToken')

    const showLoader = () => dispatch({type: SHOW_LOADER})

    const logout = () => {
        localStorage.removeItem('accessToken')

        dispatch({type: LOGOUT})

        return false
    }

    const signin = data => {
        showLoader()

        axios.post(`${url}/login`, data)
        .then(res => {
            localStorage.setItem('accessToken', res.data.access_token)

            const payload = res.data.token
    
            dispatch({type: SIGNIN, payload})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.error, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})
        })
    }

    const fetchArticles = async ( page = 1, filters = '') => {
        showLoader()
        axios.get(`${url}/articles?page=${page}&limit=10${filters && '&title='+filters}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            let articles

            if (page > 1) {
                articles = {...state.articles}

                articles.data.push(...res.data.data)
            } else {
                articles = { data: res.data.data, last_page: res.data.last_page }
            }

            const payload = articles

            dispatch({type: FETCH_ARTICLES, payload})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const createArticle = ( article ) => {
        showLoader()

        axios.post(`${url}/admin/articles`, article, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            let articles = {...state.articles}
            
            articles.data.unshift(res.data.result)
            articles.data.length >= 10 && articles.data.pop()

            const payload = { message: 'Article successfully created!', type: 'success', articles}
            dispatch({type: CREATE_ARTICLE, payload})
        }).catch(e => {
            let message = 'Something went wrong'

            if (e.response?.data?.errors) {
                for(const [key, value] of Object.entries(e.response.data.errors)) {
                    message = value
                }
            }

            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const updateArticle = (data, id) => {
        showLoader()

        axios.put(`${url}/admin/articles/${id}`, data, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            let articles = {...state.articles}

            articles.data = articles.data.map(article => (
                article.id == id ? res.data.result : article
            ))

            const payload = { message: 'Aticle successfully update!', type: 'success', articles}
            dispatch({type: UPDATE_ARTICLE, payload})
        }).catch(e => {
            let message = 'Something went wrong'

            console.log(e)
            
            if (e.response?.data?.errors) {
                for(const [key, value] of Object.entries(e.response.data.errors)) {
                    message = value
                }
            }

            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const deleteArticle = id => {
        showLoader()

        axios.delete(`${url}/admin/articles/${id}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            const payload = { message: 'Article has been deleted!', type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
                
            fetchArticles()
        }).catch(e => {
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const fetchOrders = async ( page = 1, limit = 10) => {
        showLoader()
        axios.get(`${url}/admin/orders?page=${page}&limit=${limit}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            let orders

            if (page > 1) {
                orders = {...state.orders}

                orders.data.push(...res.data.data)
            } else {
                orders = { data: res.data.data, last_page: res.data.last_page }
            }

            const payload = orders

            dispatch({type: FETCH_ORDERS, payload})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const deleteOrder = id => {
        showLoader()

        axios.delete(`${url}/admin/orders/${id}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            const payload = { message: 'Orders has been deleted!', type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
                
            fetchOrders()
        }).catch(e => {
            let message = e.response.data.message ? e.response.data.message : 'Something went wrong'
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const fetchComments = async ( page = 1, limit = 10, status = 'new') => {
        showLoader()
        axios.get(`${url}/admin/comments?page=${page}&limit=${limit}&status=${status}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            let comments

            if (page > 1) {
                comments = {...state.comments}

                comments.data.push(...res.data.data)
            } else {
                comments = { data: res.data.data, last_page: res.data.last_page }
            }

            const payload = comments

            dispatch({type: FETCH_COMMENTS, payload})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const deleteComment = id => {
        showLoader()

        axios.delete(`${url}/admin/comments/${id}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            const payload = { message: 'Comment has been deleted!', type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
                
            fetchComments()
        }).catch(e => {
            let message = e.response.data.message ? e.response.data.message : 'Something went wrong'
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const approvedComment = id => {
        showLoader()

        axios.put(`${url}/admin/comments/${id}`, { status: 'confirmed' }, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            const payload = { message: 'Comment has been approved!', type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
                
            fetchComments()
        }).catch(e => {
            console.log(e)
            let message = e.response.data.message ? e.response.data.message : 'Something went wrong'
            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const uploadFiles = (file) => {
        const data = new FormData() 
        data.append('image', file)

        axios.post(`${url}/admin/upload-image`, data, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken,
                ContentType: 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res)
            const payload = { message: `Image has been approved! Link: https://${res.data.result}`, type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
        }).catch(e => {
            console.log(e)
            let message = e.response.data.message ? e.response.data.message : 'Something went wrong'
            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const sendEmails = ( mail ) => {
        showLoader()

        axios.post(`${url}/admin/mail`, mail, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            
            const payload = { message: 'Newsletter launched successfully', type: 'success'}
            dispatch({type: SET_MESSAGE, payload})
        }).catch(e => {
            let message = 'Something went wrong'

            if (e.response?.data?.errors) {
                for(const [key, value] of Object.entries(e.response.data.errors)) {
                    message = value
                }
            }

            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const fetchSubscribers = async ( page = 1, filters = '') => {
        showLoader()

        axios.get(`${url}/admin/subscribers?page=${page}&limit=10${filters && '&email='+filters}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            console.log(res)

            let subscribers

            if (page > 1) {
                subscribers = {...state.subscribers}

                subscribers.data.push(...res.data.data)
            } else {
                subscribers = { data: res.data.data, last_page: res.data.last_page }
            }

            const payload = subscribers

            dispatch({type: FETCH_SUBSCRIBERS, payload})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const deleteSubscribers = id => {
        showLoader()

        axios.delete(`${url}/admin/subscribers/${id}`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(() => {
            const payload = { message: 'Subscriber has been deleted!', type: 'success' }
            dispatch({type: SET_MESSAGE, payload})
                
            fetchSubscribers()
        }).catch(e => {
            let message = e.response.data.message ? e.response.data.message : 'Something went wrong'
            const payload = { message: message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    const exoportSubscribers = async () => {
        showLoader()

        axios.get(`${url}/admin/subscribers/export`, {
            headers: {
                Authorization: 'Bearer ' + state.accessToken
            }
        })
        .then(res => {
            console.log(res)

            const data = new FormData() 
            data.append('image', res.data)

            console.log(data)

            dispatch({type: EXPORT_SUBSCRIBERS})
        }).catch(e => {
            console.log(e)
            const payload = { message: e.response.data.message, type: 'danger' }
            
            dispatch({type: SET_MESSAGE, payload})

            e.response.status === 401 && logout()
        })
    }

    return (
        <DatabaseContext.Provider value={{
            signin, logout,
            fetchArticles, createArticle, deleteArticle, updateArticle,
            fetchOrders, deleteOrder,
            fetchComments, deleteComment, approvedComment, 
            uploadFiles,
            sendEmails,
            fetchSubscribers, deleteSubscribers,
            exoportSubscribers,
            subscribers: state.subscribers,
            loading: state.loading,
            notice: state.notice,
            accessToken: state.accessToken,
            articles: state.articles,
            orders: state.orders,
            comments: state.comments
        }}>
            {children}
        </DatabaseContext.Provider>
    )
}